module.exports = {
	defaultTitle: 'Nicolas Gehlert',
	logo: 'https://portfolio.smakosh.com/favicon/favicon-512.png',
	author: 'Nicolas Gehlert',
	url: 'https://ngehlert.de',
	legalName: 'Nicolas Gehlert',
	defaultDescription: 'I create web sites, web applications and native apps',
	socialLinks: {
		twitter: 'http://www.twitter.com/ngehlert',
		github: 'https://github.com/ngehlert',
		linkedin: 'https://www.linkedin.com/in/nicolas-gehlert-6b6434147/',
		youtube: 'https://www.youtube.com/user/emsi1337',
	},
	themeColor: '#009688',
	secondaryColor: '#FF9800',
	backgroundColor: '#009688',
	social: {
		facebook: 'appId',
		twitter: '@ngehlert',
	},
	address: {
		city: 'City',
		region: 'Region',
		country: 'Country',
		zipCode: 'ZipCode',
	},
	contact: {
		email: 'email',
		phone: 'phone number',
	},
	foundingDate: '2019',
	recaptcha_key: '6Le5Wb0UAAAAAEmqaTebvyoYTXsC4PkkM-1wme6o'
}
