import styled from 'styled-components';
const config = require('./../../../../../data/config');

export const Wrapper = styled.div`
  padding: 1.5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    color: ${config.themeColor};
    font-size: 1.5rem;
  }

  a:hover {
    color: ${config.secondaryColor};
  }
`;
