import React from 'react';
import { Link } from 'gatsby';
import { Container } from 'Common';
import NavbarLinks from '../NavbarLinks';
import { Wrapper } from './styles';
const config = require('./../../../../../data/config');

const Navbar = () => (
  <Wrapper as={Container}>
    <Link to="/">{config.defaultTitle}</Link>
    <NavbarLinks desktop />
  </Wrapper>
);

export default Navbar;
