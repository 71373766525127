import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Wrapper } from './styles';

const NavbarLinks = ({ desktop }) => (
  <Wrapper desktop={desktop}>
    <AnchorLink href="#developapa">Developapa</AnchorLink>
    <AnchorLink href="#confin">Confin</AnchorLink>
    <AnchorLink href="#made-my-day">Made&#8901;my&#8901;day</AnchorLink>
    <AnchorLink href="#zatacka">Zatacka</AnchorLink>
    <AnchorLink href="#contact">Contact</AnchorLink>
  </Wrapper>
);

export default NavbarLinks;
